import { DownOutlined, UpOutlined} from '@ant-design/icons';
import React from 'react';
import { connect } from "react-redux";

const LeftActionCall = (
    {
        state,
        cellData,
        reacted,
        itemSize,
        onClickRow,
        bgColor,
        innerRef,
        isSub,
        resource,
        hasSubOrder,
    }) => {
    const loadArea = state[resource];
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const width = header.width;
    let cellStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        fontSize: '17px',
        left: 0,
        backgroundColor: bgColor,
    };

    const cellStyleValue = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        position: 'sticky',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: bgColor,
        left: 0
    };

    return (
        <>
            {!isSub && hasSubOrder ?
                <div ref={innerRef} onClick={() => { onClickRow() }} className='table-body-cell' style={cellStyle}>
                    {reacted ? <UpOutlined style={{ fontSize: '10px', color: '#000000' }} /> : <DownOutlined style={{ fontSize: '10px', color: '#000000' }} />}
                </div> :
                <div ref={innerRef} className='table-body-cell' style={cellStyleValue}></div>
            }
        </>
    
    );
};
export default connect(state => ({
    state: state
}))(LeftActionCall);