import React, { useState } from 'react';
import { connect } from "react-redux";
import axios from '../../../../axios/axios';
import { Radio, Input, message } from "antd";
import { useData } from '../../../../hook/data/useData';
import { openPopup } from '../../../../../store/actions/popup/popup';
import './styles.css';
const { TextArea } = Input;

const SONUCancelation = props => {
    console.log(props)
    const { sectionData,  closeAction,  saveAction } = props;
    const [data, dispatch] = useData(sectionData);
    const [sonuType, setSonuType] = useState(1);
    const [sonuPrice, setSonuPrice] = useState(sectionData.price);
    const [sonuPartialPrice, setSonuPartialPrice] = useState('');
    const [sonuNote, setSonuNote] = useState('');
    const resource = ''; //TODO

    const confirmSonu = () => {
        if (!sonuPartialPrice && sonuType === 2) {
            
            message.warn('For Partial SONU, please enter an amount');
        } else {
            const requestData = {
                id: sectionData.id,
                sonuPrice: sonuPrice,
                sonuType: sonuType,
                sonuNote: sonuNote,
            }
            saveAction(requestData)

            // axios.put(resource, requestData)
            //         .then(response => {
            //             if (response) {
            //                 const result = response.data;
            //                 if (result) {
            //                     if (result.hasError) {
            //                         message.error(result.message);
            //                         return;
            //                     }
            //                 }
            //             }
            //         });
        }
    }

    const handleSonuType = e => {
        setSonuType(e.target.value);
        if (e.target.value === 1) {
            setSonuPrice(sectionData.price);
            setSonuPartialPrice('');
        } else if (e.target.value === 2) {
            setSonuPrice(sonuPartialPrice);
            setSonuPartialPrice('');
        }
    };

    const handleSonuPrice = e => {
        setSonuType(2);
        setSonuPartialPrice(e.target.value);
        setSonuPrice(e.target.value);
    }

    const buttonDisabled = sonuType === 1 && !sectionData.price;

    return <div className='sonuModal__container'>
            <header className='SONU-Modal__header'>
                <p className='sonu-modal__header-text'>Select Cancellation Amount</p>
                <div effect="material" className='button white mini close SONU-Modal__header-closeBtn' command="close" onClick={closeAction}>
                    <icon>close</icon>
                </div>
            </header>
            <Radio.Group value={sonuType} onChange={handleSonuType}>
                <div className='sonu-input__container'>
                    <Radio value={1}>Sonu</Radio>
                    <Input
                        className='sonu__input sonu-input__disabled'
                        value={sectionData.price}
                        type="number"
                        disabled
                        addonBefore='$'
                    /> 
                </div>
                <div className='sonu-input__container'>
                    <Radio value={2}>Partial Sonu</Radio>
                    <Input
                        className='sonu__input'
                        value={sonuPartialPrice}
                        onChange={e => handleSonuPrice(e)}
                        type="number"
                        addonBefore='$'
                    /> 
                </div>
                <div className='sonu-input-notes__container'>
                    <TextArea
                        value={sonuNote}
                        onChange={(e) => setSonuNote(e.target.value)}
                        placeholder="NOTES"
                        autoSize={{ minRows: 2, maxRows: 2 }}
                    />
                </div>
            </Radio.Group>
            <div className='sonu-buttons__container'>
                <button 
                    className="sonu-button" 
                    onClick={closeAction}>
                    CANCEL
                </button>
                <button 
                    disabled={buttonDisabled} 
                    className={`sonu-button sonu-button-confirm ${buttonDisabled && 'buttonDisabled'}`} 
                    onClick={confirmSonu}>
                    CONFIRM
                </button>
            </div>
        </div>
    ;
};

const mapDispatchToProps = dispatch => {
    return {
        openConfirm: (data) => dispatch(openPopup(data)),
        openAlert: (data) => dispatch(openPopup(data))
    };
};

export default connect(null, mapDispatchToProps)(SONUCancelation);