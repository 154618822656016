import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { notification } from 'antd';
import axios from '../../axios/axios';
import { openPopup, closePopup, closePopupAsync } from '../../../store/actions/popup/popup';
import { PopupTypes } from '../../components/custom/popup/popup';
import { CommonLabels } from '../../../project/Defines';
import { CloneObject } from '../../helper/common';

export const useDelete = (title, resource, backUrl, search, setDeleting, afterDelete) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const startDelete = (master, key) => dispatch(openPopup({
        windowKey: 'wndConfirmDelete',
        type: PopupTypes.Confirm,
        title: CommonLabels.Messages.ConfirmDeleteTitle(title, master),
        text: CommonLabels.Messages.ConfirmDeleteText,
        buttonYesText: CommonLabels.Buttons.Delete,
        buttonNoText: CommonLabels.Buttons.Cancel,
        yesCallback: () => {
            setDeleting(true);
            axios.delete(resource, {
                params: {
                    id: key
                }
            })
                .then(response => {
                    setDeleting(false);
                    if (response) {
                        const result = response.data;
                        if (result) {
                            history.push({
                                pathname: backUrl,
                                search: search,
                                state: {
                                    deleted: true,
                                    message: result.message
                                }
                            });
                            afterDelete && afterDelete();
                        }
                    }
                });
        }
    }));
    return startDelete;
};

export const useSONUData = (title, resource, config) => {
    const dispatch = useDispatch();
    const startDelete = (master, key, data, sendRequest = true, afterAction) => {
        const windowKey = CommonLabels.Messages.ConfirmDeleteTitle(title, master);
        const newConfig = CloneObject(config);
        newConfig.Form.DefaultId = key;

        return dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            sectionData: data,
            fullScreen: false,
            title: 'windowKey',
            type: PopupTypes.SONUModal,
            bodyProps: { windowKey },
            config: newConfig.Form,
            saveAction: (requestData) => {
                console.log(data)
                if (sendRequest) {
                    axios.put(resource, requestData)
                        .then(response => {
                            if (response) {
                                const result = response.data;
                                if (result) {
                                    if (result.hasError) {
                                        message.error(result.message);
                                        return;
                                    }
                                    afterAction && afterAction();
                                }
                            }
                        });
                } else {
                    afterAction && afterAction(data);
                }
                dispatch(closePopupAsync('wndConfirmDelete'));
            },
            closeAction: () => {
                dispatch(closePopup('wndConfirmDelete'));
            }
        }))
    };
    return startDelete;
}

export const useDeleteReason = (title, resource, config, backUrl, search, setDeleting, afterDelete) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const startDelete = (master, key, data, sendRequest = true, afterAction) => {
        const windowKey = CommonLabels.Messages.ConfirmDeleteTitle(title, master);

        const newConfig = CloneObject(config);
        newConfig.Form.DefaultId = key;

        return dispatch(openPopup({
            windowKey: 'wndConfirmDelete',
            sectionData: data,
            fullScreen: false,
            title: windowKey,
            type: PopupTypes.Prompt,
            bodyProps: { windowKey },
            config: newConfig.Form,
            saveAction: (data) => {
                const requestData = {
                    id: key,
                    cancelReason: data.cancelReason
                };
                if (sendRequest) {
                    axios.put(resource, requestData)
                        .then(response => {
                            if (response) {
                                const result = response.data;
                                if (result) {
                                    if (result.hasError) {
                                        message.error(result.message);

                                        return;
                                    }
                                    if (backUrl) {
                                        history.push({
                                            pathname: backUrl,
                                            search: search,
                                            state: {
                                                deleted: true,
                                                message: result.message
                                            }
                                        });
                                    }
                                    afterAction && afterAction();
                                }
                            }
                        });
                } else {
                    afterAction && afterAction(data);
                }

                dispatch(closePopupAsync('wndConfirmDelete'));
            },
            closeAction: () => {
                dispatch(closePopup('wndConfirmDelete'));
            }
        }))
    };
    return startDelete;
};

export const useDeleteItem = (title, action) => {
    const dispatch = useDispatch();
    const startDelete = (master, data) => dispatch(openPopup({
        windowKey: 'wndConfirmDelete',
        type: PopupTypes.Confirm,
        title: CommonLabels.Messages.ConfirmDeleteTitle(title, master),
        text: CommonLabels.Messages.ConfirmDeleteText,
        buttonYesText: CommonLabels.Buttons.Delete,
        buttonNoText: CommonLabels.Buttons.Cancel,
        yesCallback: () => {
            action(data);
        }
    }));
    return startDelete;
};

export const useCheckReference = (deleteData, resource) =>
    (master, screenKey, id, hasreference) => {
        if (hasreference) {
            axios({
                url: `${resource}/hasreference/${id}`,
                method: 'GET'
            })
                .then(response => {
                    if (response.data) {
                        notification.error({
                            description: "You can't delete the region, as there is a connection between region location(s)! Please first remove region location(s).",
                            duration: 20,
                            style: {
                                padding: '30px'
                            }
                        });

                        return;
                    }

                    deleteData(master, screenKey);
                })
        }

        else {
            deleteData(master, screenKey);
        }
};
