import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Select from 'react-select'
import { message, Input, Switch } from 'antd';
import { debounce } from 'lodash';
import Scroll from "../../scroll/scroll";
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import { saveScreenSwap } from '../../../../store/actions/authentication/authentication';
import { load, unreactOrders, assignOrdersToNewLoad, addNewLoad, createSubLoadAssignOrders }
    from '../../../../store/actions/loadArea/loadArea';
import { openPopup, closePopup, closePopupAsync } from "../../../../store/actions/popup/popup";
import { PopupTypes } from "../../custom/popup/popup";
import { ElementTypes, ElementValidations, GenerateToolBars, ValueListUrls } from '../../../config';
import LocationConfig from '../../../screens/Location/config';
import Manifest from '../../../screens/Manifest/config';
import axios from '../../../../codeless/axios/axios';
import ToolBarButton from '../../../elements/toolBarButton';
import LumperModal from './LumperModal';
import AssignOrdersModalNewLoad from './AssignOrdersModalNewLoad';

const ToolBar = props => {
    const {
        mainResource,
        saveScreenSwap,
        screens,
        state,
        unreactOrders,
        assignOrdersToNewLoad,
        createSubLoadAssignOrders,
        addNewLoad,
        openPopup,
        load,
        resource,
        userRole,
        filter,
        handleSelectChange,
        setSearchInput,
        setFilterInput,
        onlyTBDOrders,
        setOnlyTBDOrder,
    } = props;

    const [lumperModalIsOpen, setLumperModalIsOpen] = useState(false);
    const openModalNewLoad = state[resource].openModalNewLoad;
    const [assignOrdersModalIsOpen, setAssignOrdersModalIsOpen] = useState(false);
    const allAvailableOrders = resource === 'load' ? state?.load?.orders : state?.outboundload?.orders;

    useEffect(() => {
        setAssignOrdersModalIsOpen(openModalNewLoad);
    }, [openModalNewLoad]);

    const swap = screens && screens[mainResource] ? screens[mainResource].swap : false;
    const loadArea = state[resource];
    const selectedAvailableOrders =
        (loadArea && loadArea.regionsAvailableOrders) ?
            loadArea.regionsAvailableOrders.filter(
                o => o.rowState.type === 'order' && o.rowState.reacted) : [];
    const selectedAssignedOrders =
        (loadArea && loadArea.loadsAssignedOrders) ?
            loadArea.loadsAssignedOrders.filter(
                o => o.rowState.type === 'order' && o.rowState.reacted) : [];

    const availableSelection = (load && loadArea.orders) ?
        loadArea.orders.filter(
            o => selectedAvailableOrders.findIndex(ao =>
                ao.rowState.orderData.id === o.id) !== -1 ||
                selectedAssignedOrders.findIndex(ao =>
                    ao.rowState.orderData.id === o.id) !== -1) : [];
    const totalSpace = availableSelection.reduce((sum, o) => sum + o.space, 0);
    const totalPallet = availableSelection.reduce((sum, o) => sum + o.palletCount, 0);
    const totalWeight = availableSelection.reduce((sum, o) => sum + o.weight, 0);

    const success = (text) => {
        message.success(`${text}`);
    };

    const createManifest = () => {
        const windowKey = "Manifest";
        const config = Manifest;
        const title = `Edit ${config.Title}`;
        openPopup({
            windowKey,
            fullScreen: false,
            title: title,
            type: PopupTypes.ConfigSection,
            bodyProps: { windowKey },
            config: {
            Title: 'Create Manifest',
            SectionGroups: [
                {
                    Sections: [
                        {
                            Fields: {
                                week: {
                                    Title: 'Select Week',
                                    Field: ElementTypes.LabelField(),
                                }
                            }
                        }
                    ]
                }
            ],
            ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
        },
            data: {
                week: `${new Date().getWeek()}`,
                noteCheckBeforeSave:true,
            },
            onSave: (result, isClose) => {
                axios({
                    url: `/load/createmanifestforweek`,
                    method: 'put',
                    headers:
                    {
                        "Content-Type": "application/json-patch+json"
                    },
                    data: result.week,
                }).then((res) => {
                    success(res?.data?.message)
            
                })
                if (isClose) {
                    closePopupAsync(windowKey);
                }
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const createSabload = () => {
        const windowKey = "SubLoad";
        openPopup({
            windowKey,
            fullScreen: false,
            title: 'Create Sub Load',
            type: PopupTypes.ConfigSection,
            bodyProps: { windowKey },
            data: {
                dropLocationId: undefined
            },
            config: {
                Title: 'Select Destination',
                SectionGroups: [
                    {
                        Sections: [
                            {
                                Fields: {
                                    dropLocationId: {
                                        Title: 'DEL WH',
                                        Field: ElementTypes.Generic(ElementTypes.ComboAddWithCache, {
                                            dataSource: ValueListUrls.LocationDoor,
                                            addparams: {
                                                TitlePrefix: 'Drop',
                                                PopupConfig: LocationConfig,
                                                ValueField: 'defLocationDoorId'
                                            },
                                            selectedValueType: 'drop2WH'
                                        }),
                                        Validations: [ElementValidations.Required()]
                                    }
                                }
                            }
                        ]
                    }
                ],
                ToolBars: GenerateToolBars(['SaveClose', 'Cancel'])
            },
            onSave: (result, isClose) => {
                createSubLoadAssignOrders(resource, selectedAssignedOrders, result.dropLocationId);
                if (isClose) {
                    closePopupAsync(windowKey);
                }
            },
            closeAction: () => {
                closePopup(windowKey);
            }
        });
    };

    const trySaveScreenSwap = params => {
        document.getElementById('main').scrollLeft = 0;
        saveScreenSwap(params.mainResource, params.swap)
    };

    const tryAssignOrdersToNewLoad = params => resource === 'load' ? 
            assignOrdersToNewLoad(params.resource, params.orders, 0, state.load.orders) :
            assignOrdersToNewLoad(params.resource, params.orders, 0, state.outboundload.orders);

    const forAdminAndCustomerRoles = userRole === 'Admin' || userRole === 'Customer';

    const pickLocations = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.pickLocation : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.pickLocation : '' }
    });

    const dropLocations = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropLocation : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropLocation : '' }
    });

    let delNumbers = [];
    if (resource === 'load') {
        delNumbers = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
            return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inDeliverNummber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inDeliverNummber : '' }
        });
    } else {
        delNumbers = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
            return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outDeliverNummber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outDeliverNummber : '' }
        });
    };

    const stateNames = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropStateName : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropStateName : '' }
    });

    // let apptStatuses = [];
    // if (resource === 'load') {
    //     apptStatuses = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
    //         return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inboundAppointmentStatus : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inboundAppointmentStatus : '' }
    //     });
    // } else {
    //     apptStatuses = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
    //         return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outboundAppointmentStatus : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outboundAppointmentStatus : '' }
    //     });
    // };

    const uniquePickLocations = pickLocations?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const uniqueDropLocations = dropLocations?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const uniqueDelNumbers = delNumbers?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    // constant data for statuses
    const uniqueApptStatuses = [
        { value: 2, label: 'Open' },
        { value: 1, label: 'Change' },
        { value: 3, label: 'Pending' },
        { value: 4, label: 'Confirmed' },
        { value: 'All', label: 'All' },
    ];

    const uniqueStateNames = stateNames?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const [pickLocation, setPickLocations] = useState('');
    const [dropLocation, setDropLocations] = useState('');
    const [delNumber, setDelNumber] = useState('');
    const [apptStatus, setApptStatus] = useState('');
    const [stateName, setStateName] = useState('');
    const [checkboxSelected, setCheckboxSelected] = useState(false);

    const handleChangePick = option => {
        handleSelectChange(option.value, 'pick');
        setPickLocations(option);
    };

    const handleChangeDrop = option => {
        handleSelectChange(option.value, 'drop');
        setDropLocations(option);
    };

    const handleChangeDelNumber = option => {
        handleSelectChange(option.value, 'del');
        setDelNumber(option);
    };

    const handleChangeApptStatus = option => {
        handleSelectChange(option.value, 'apptStatus');
        setApptStatus(option);
    };

    const handleChangeStateName = option => {
        handleSelectChange(option.value, 'stateName');
        setStateName(option);
    };

    const noLumper = selectedAssignedOrders?.filter(order => order.rowState.orderData?.dropWhLumper === 1).length > 0;

    const debounced = debounce(() => {
        message.warning("It's not possible to add a lumper for this WH", 1)
    }, 700);
    
    const handleOpenLumperModal = () => {
        if (noLumper) {
            debounced();
        } else {
            selectedAssignedOrders.length && setLumperModalIsOpen(true);
        }
    };

    const handleCheckboxSelected = () => {
        setCheckboxSelected(!checkboxSelected);
        setFilterInput('');
        setSearchInput('');
    };

    const handleTBDCheckboxSelected = () => {
        setOnlyTBDOrder(!onlyTBDOrders)
    }

    const handleRefresh = () => {
        load(resource);
        setOnlyTBDOrder(false);
    }

    const styles = {
        control: (base, state) => ({
            ...base,
            borderRadius: '8px',
            boxShadow: 'none',
            minHeight: '22px',
            minWidth: '150px',
            paddingLeft: '10px',
            height: '24px',
            alignContent: 'center',
        }),
        menu: (base, state) => ({
            ...base,
            boxShadow: '0',
            top: 20,
        }),
        input: (base, state) => ({
            ...base,
            height: 0,
            position: 'absolute',
            top: '0',
            marginTop: '-2px',
        }),
        valueContainer: (base, state) => ({
            height: '-webkit-fill-available',
        }),
        singleValue: (base, state) => ({
            maxWidth: '170px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }),
        menuList: (base, state) => ({
            ...base,
            position: 'fixed',
            maxHeight: "-webkit-fill-available",
            padding: 0,
            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            color: state.isSelected ? "#000" : "#000",
            backgroundColor: state.isSelected ? "lightGrey" : "#fff",
            border: '1px solid lightGray'
        }),
        indicatorsContainer: () => ({
            height: 'max-content',
        }),
        indicatorContainer: () => ({
            padding: '0 8px!important',
        }),
    };

    const stylesDel = {
        ...styles,
        control: (base, state) => ({
            ...base,
            borderRadius: '8px',
            boxShadow: 'none',
            minHeight: '22px',
            minWidth: '80px',
            paddingLeft: '10px',
            height: '24px',
            alignContent: 'center',
        }),
    };

    return (<toolbar 
                onMouseDown={(e)=> document.activeElement.blur()} 
                scroll="" 
                lightgrey="" 
                className={`subtoolbar ${filter ? 'withFilter' : ''}`}>        
        <Scroll>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <wrap style={{ marginLeft: '14px'}}>
                    <action>
                        <ToolBarButton text="Swap Tables"
                            title='Swap'
                            onClick={trySaveScreenSwap}
                            clickParams={{ mainResource: mainResource, swap: !swap }}
                        />
                        <ToolBarButton text="Refresh"
                            inProcess={loadArea.refreshInProcess}
                            title='Refresh Orders'
                            onClick={handleRefresh}
                            clickParams={resource}
                        />
                        <ToolBarButton 
                            primary
                            className="add-load"
                            text="Add Load"
                            inProcess={loadArea.addNewInProcess || loadArea.createInProcess || loadArea.createSubInProcess}
                            onClick={addNewLoad}
                            clickParams={resource} />
                        {loadArea.loadType === 'inbound' && forAdminAndCustomerRoles ?
                            <ToolBarButton text="Add Manifest"
                                title='Add Manifest'
                                onClick={createManifest} />
                            : null}
                        {loadArea.loadType === 'outbound' ?
                            <ToolBarButton 
                                primary 
                                text="Add Lumper"
                                className="add-load"
                                title='Add Lumper'
                                onClick={() => handleOpenLumperModal()} />
                            : null} 
                    </action>
                    <action right="">
                        {
                            availableSelection.length > 0 && (<>
                                {
                                    selectedAssignedOrders.filter(a => a.rowState.isSub || a.rowState.subId).length === 0 &&
                                    <ToolBarButton primary text="Create New Load"
                                        inProcess={loadArea.addNewInProcess || loadArea.createInProcess || loadArea.createSubInProcess}
                                        title='Create New Load'
                                        onClick={tryAssignOrdersToNewLoad}
                                        clickParams={{ resource: resource, orders: [...selectedAvailableOrders, ...selectedAssignedOrders]}} />
                                }
                                {
                                    availableSelection.length === selectedAssignedOrders.filter(as => as.rowState.allowCreateSubOrder &&
                                        as.rowState.loadData.id === selectedAssignedOrders[0].rowState.loadData.id).length && resource === 'outboundload' &&
                                    <ToolBarButton primary text="Create Sub Load"
                                        inProcess={loadArea.addNewInProcess || loadArea.createInProcess || loadArea.createSubInProcess}
                                        title='Create Sub Load'
                                        onClick={createSabload} />
                                }
                                <div className="line form_fields counts">
                                    <div className="data_label counts-tab">Space Count</div>
                                    <div className="data_read_count">
                                        <b><text purple-rose={totalSpace > 31 ? '' : null}>{totalSpace?.toLocaleString()}</text></b>
                                    </div>
                                </div>
                                <div className="line form_fields counts">
                                    <div className="data_label counts-tab">Pallet Count</div>
                                    <div className="data_read_count">
                                        <b><text purple-rose={totalPallet > 31 ? '' : null}>{totalPallet}</text></b>
                                    </div>
                                </div>
                                <div className="line form_fields counts">
                                    <div className="data_label counts-tab">Total Weight</div>
                                    <div className="data_read_count">
                                        <b><text purple-rose={totalWeight > 30000 ? '' : null}>{totalWeight?.toLocaleString()}</text></b>
                                    </div>
                                </div>
                                <ToolBarButton text="Unselect All"
                                    title='Unselect All'
                                    onClick={unreactOrders}
                                    clickParams={resource}
                                />
                            </>)
                        }
                    </action>
                </wrap>
                <div className='filters_container filter-search-container'>
                    <div style={{display: 'flex'}}>
                        <div className='filter_container__select'>
                            <div className='filter_title'>FILTER</div>
                            <Select
                                    options={uniquePickLocations}
                                    name="pickLocation"
                                    placeholder = "Pick"
                                    value={pickLocation && pickLocation}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangePick(option)}
                                    styles={styles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>
                        <div className='filter_container__select'>
                            <Select
                                    options={uniqueDropLocations}
                                    name="dropLocation"
                                    placeholder = "Delivery"
                                    value={dropLocation && dropLocation}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangeDrop(option)}
                                    styles={styles}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>
                        <div className='filter_container__select'>
                            <Select
                                    options={uniqueDelNumbers}
                                    name="del"
                                    placeholder = "DEL#"
                                    value={delNumber && delNumber}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangeDelNumber(option)}
                                    styles={stylesDel}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>
                        {resource === 'outboundload' && <div className='filter_container__select'>
                            <Select
                                    options={uniqueStateNames}
                                    name="stateName"
                                    placeholder = "State"
                                    value={stateName && stateName}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangeStateName(option)}
                                    styles={stylesDel}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>}
                        <div className='filter_container__select'>
                            <Select
                                    options={uniqueApptStatuses}
                                    name="apptStatus"
                                    placeholder = "Appt status"
                                    value={apptStatus && apptStatus}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangeApptStatus(option)}
                                    styles={stylesDel}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>
                    </div>
                    <div className='filters__main-container'>
                        <div className='filter_container__select'>
                            <Switch defaultChecked onChange={() => handleCheckboxSelected()} />
                            {!checkboxSelected ? <>
                                <div className='search_title'>Search</div>
                                <Input
                                    className='load__input_search'
                                    onChange={e => setSearchInput(e.target.value.toLowerCase())}
                                    placeholder="PO#"
                                    type="text"
                                /> 
                            </> : null}
                            {checkboxSelected ? <>
                                <div className='search_title'>Filter</div>
                                <Input
                                    className='load__input_search'
                                    onChange={e => setFilterInput(e.target.value.toLowerCase())}
                                    placeholder="PO#"
                                    type="text"
                                /> 
                            </> : null}
                            <div className='TBD-checkbox__title'>TBD</div>
                            <Switch checked={onlyTBDOrders} onChange={() => handleTBDCheckboxSelected()} />
                        </div>
                    </div>
                </div>
            </div>
        </Scroll>
        {!props.isHiddenSwitch && <HideShowMenuSwitch />}
        {lumperModalIsOpen &&
            <LumperModal
                resource={resource}
                lumperModalIsOpen={lumperModalIsOpen}
                setLumperModalIsOpen={setLumperModalIsOpen}
                selectedAssignedOrders={selectedAssignedOrders}
                >
            </LumperModal>
        }
        {assignOrdersModalIsOpen && 
            <AssignOrdersModalNewLoad
                assignOrdersModalIsOpen={assignOrdersModalIsOpen}
                setAssignOrdersModalIsOpen={setAssignOrdersModalIsOpen}
                resource={resource}
                allAvailableOrders={allAvailableOrders}
                state={state}
            >
            </AssignOrdersModalNewLoad>}
        </toolbar>);
};

const mapStateToProps = state => ({
    state: state,
    screens: state.auth.profileSettings && state.auth.profileSettings.screens,
    userRole: state.auth.userRole,
});

const mapDispatchToProps = (dispatch) => ({
    load: (resource) => dispatch(load(resource)),
    openAlert: (key) => dispatch(openPopup(key)),
    saveScreenSwap: (resource, swap) => dispatch(saveScreenSwap(resource, swap)),
    unreactOrders: resource => dispatch(unreactOrders(resource)),
    assignOrdersToNewLoad: (resource, orders, index, allOrders) => dispatch(assignOrdersToNewLoad(resource, orders, 1, allOrders)),
    createSubLoadAssignOrders: (resource, orders, dropLocationId) =>
        dispatch(createSubLoadAssignOrders(resource, orders, dropLocationId)),
    addNewLoad: resource => dispatch(addNewLoad(resource)),
    openPopup: data => dispatch(openPopup(data)),
    closePopup: key => dispatch(closePopup(key)),
    closePopupAsync: key => dispatch(closePopupAsync(key))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);