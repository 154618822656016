import React, { useCallback, useRef, useEffect, useState } from 'react';
import { connect } from "react-redux";
import Cell from './cell';
import LeftActionCall from './leftActionCall';
import { reactOnAssignedItem } from '../../../../store/actions/accountReceivableActions/accountReceivableActions';
import PriceRecalculationModal from './PriceRecalculationModal';

const Row = ({
        data,
        style,
        itemSize,
        reactOnAssignedItem,
        state,
        listRef,
        startOnScroll,
        resource,
        setUploadRowState,
        setUploadModalIsOpen,
        setCommentsModalIsOpen,
        setChangeRequestModalIsOpen,
        changeRequestAcceptModalIsOpen,
        setChangeRequestAcceptModalIsOpen,
        }) => {
    const rowRef = useRef({});
    const loadArea = state[resource]
    const [priceRecalculationModalIsOpen, setPriceRecalculationModalIsOpen] = useState(false);

    const subOrdersOrderIDs = loadArea && loadArea?.subOrders?.length && loadArea?.subOrders?.map(sub => sub.orderId);
    const hasSubOrderInAR = data?.rowState.type === 'order' && subOrdersOrderIDs && subOrdersOrderIDs?.includes(data?.rowState.orderData?.id) && !data.rowState.isSub;
    let color = data.rowState.isSub ? '#b4d5bc' : 'inherit';

    const hasSubOrderInAllPages = data?.rowState.orderData.subOrderCount === 0;
    const deliveredSubOrdersCount = loadArea && loadArea?.subOrders?.length && loadArea?.subOrders?.filter(sub => sub.orderId === data.rowState.orderData?.id).length;
    const isDetoured = data?.rowState.orderData.subOrderCount > deliveredSubOrdersCount;

    useEffect(() => {
        if (rowRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [rowRef, listRef]);

    const onClickRow = useCallback(() => {
        reactOnAssignedItem(resource, data.rowState.id, data.rowState.isSub);
    }, [reactOnAssignedItem, data, resource]);

    style.width = 'max-content';
    const rowStyle = { ...style, ...{ height: `${itemSize}px`, backgroundColor: color } };
    
    const cellContent = (i, ind) => {
        const header = loadArea.headers.find(h => h.key === i.key);

        if (header.leftAction) {
            return (<LeftActionCall
                innerRef={rowRef}
                key={ind}
                isSub={data.rowState.isSub}
                hasSubOrder={hasSubOrderInAR}
                cellData={i}
                reacted={data.rowState.reacted}
                itemSize={itemSize}
                onClickRow={onClickRow}
                bgColor={color}
                resource={resource}
            />);
        }

        return (<Cell
            key={ind}
            cellData={i}
            reacted={data.rowState.reacted}
            type={data.rowState.type}
            itemSize={itemSize}
            onClickRow={onClickRow}
            startOnScroll={startOnScroll}
            rowState={data.rowState}
            resource={resource}
            setUploadRowState={setUploadRowState}
            setUploadModalIsOpen={setUploadModalIsOpen}
            setCommentsModalIsOpen={setCommentsModalIsOpen}
            setChangeRequestModalIsOpen={setChangeRequestModalIsOpen}
            changeRequestAcceptModalIsOpen={changeRequestAcceptModalIsOpen}
            setChangeRequestAcceptModalIsOpen={setChangeRequestAcceptModalIsOpen}
            setPriceRecalculationModalIsOpen={setPriceRecalculationModalIsOpen}
            hasSubOrder={hasSubOrderInAllPages}
            isDetoured={isDetoured}
        />);
    };

    return data ?
        <div className='resp-table-row' style={{ ...rowStyle, display:'flex' }}>
            {data.row.map(cellContent)}
            {priceRecalculationModalIsOpen ?
                <PriceRecalculationModal
                    priceRecalculationModalIsOpen={priceRecalculationModalIsOpen}
                    setPriceRecalculationModalIsOpen={setPriceRecalculationModalIsOpen}
                    rowState={data.rowState}
                    resource={resource}
                /> : ''
            }
        </div> : null;
    };

export default connect(state => ({
    state: state
}), dispatch => ({
    reactOnAssignedItem: (resource, id, isSub) => dispatch(reactOnAssignedItem(resource, id, isSub)),
}))(Row);