import moment from 'moment';

import { Defines } from '../../project/Defines';
import { ColumnType } from '../config';
import { AddMonths, Compare } from './common';

export const RegExps = {
    Email: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    EmailList: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/, // TOTO Tatul: Restrict
    Time: /^([01]\d|2[0-3]):([0-5]\d)$/,
    Phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    Other: ''
};

export const RegexpTest = (regexp, data) => {
    if (!regexp.test(data)) {
        return false;
    }
    return true;
};

export const EmailTest = (data) =>
    data.toLowerCase && typeof data.toLowerCase === 'function' && RegexpTest(RegExps.Email, data.toLowerCase());

export const EmailListTest = (data) =>
    data.toLowerCase && typeof data.toLowerCase === 'function' && RegexpTest(RegExps.EmailList, data.toLowerCase());

export const TimeTest = (data) =>
    data.toLowerCase && typeof data.toLowerCase === 'function' && RegexpTest(RegExps.Time, data.toLowerCase());

export const PhoneTest = (data) =>
    data.toLowerCase && typeof data.toLowerCase === 'function' && RegexpTest(RegExps.Phone, data.toLowerCase());

export const ValidationErrorClass = 'input-validation-error';
export const ValidationWarningClass = 'input-validation-warning';

export const ValidateElement = (validations, data, title, object) => {
    const invalMessages = [];
    validations.forEach(val => {
        const message = val(data, title, object);
        if (message) {
            invalMessages.push(message);
        }
    });
    return invalMessages;
};

export const ValidateWrap = (wrap, data, messageDispatch, identity) => {
    let passValidation = true;
    for (const st in wrap) {
        if (!ValidateFields(wrap[st].Fields, data, messageDispatch, identity)) {
            passValidation = false;
        }
    }
    return passValidation;
};

export const ValidateScreenToolbar = (sectionToolbar, data, messageDispatch, identity) => {
    let passValidation = true;
    for (const st in sectionToolbar) {
        if (!ValidateFields(sectionToolbar[st].Fields, data, messageDispatch, identity)) {
            passValidation = false;
        }
    }
    return passValidation;
};

export const ValidateSection = (section, data, messageDispatch, identity) => {
    return ValidateFields(section.Fields, data, messageDispatch, identity);
};

export const ValidateFields = (fields, data, messageDispatch, identity, TBD) => {
    let passValidation = true;
    for (const f in fields) {
        const field = fields[f];
        if (Array.isArray(field)) {
            for (const gf in field) {
                if (!ValidateFields(field[gf].Fields, data, messageDispatch, identity)) {
                    passValidation = false;
                }
            }
        }
        else {
            const element = field.Field && typeof field.Field === 'function' && field.Field(field.Data, data)();
            if (element && field.Validations && field.Validations.length > 0) {
                const invalMessages = ValidateElement(field.Validations, field.Data, field.Title, data);
                const messageType = identity ? `${identity}_${f}` : f;
                messageDispatch && messageDispatch({ type: messageType, messages: invalMessages });
                
                // TODO
                // const requiredFields = ['Customer is required', 'Temp is required', 'Pick Location is required', 'READY DATE is required', 'DUE DATE is required', 'DEL WH is required'];
                // const notRequiredsForTBD = ['PO# is required', 'PAL is required', 'LBS is required'];
                // if (TBD && notRequiredsForTBD.includes(invalMessages[0]) && !requiredFields.includes(invalMessages[0]) ) {
                //     passValidation = true;
                // } else
                if (invalMessages.length > 0) {
                    passValidation = false;
                }
            }
            if (element && field?.Warnings?.length) {
                const invalMessages = ValidateElement(field.Warnings, field.Data, field.Title, data);
                const messageType = identity ? `${identity}_${f}` : f;
                messageDispatch && messageDispatch({ type: messageType, messages: invalMessages, messageType: 'warning' });
            }
        }
    }
    return passValidation;
};

export const ValidateSectionGroups = (sectionGroups, data, messageDispatch, identity) => {
    let passValidation = true;
    for (const sg in sectionGroups) {
        const secrionGroup = sectionGroups[sg];
        if(!ValidateSections(secrionGroup.Sections, data, messageDispatch, identity)) {
            passValidation = false;
        }
    }
    return passValidation;
};

export const ValidateSections = (sections, data, messageDispatch, identity) => {
    let passValidation = true;
    for (const s in sections) {
        const section = sections[s];
        if (!ValidateSection(section, data, messageDispatch, identity)) {
            passValidation = false;
        }
    }
    return passValidation;
};

export const ValidateTabstrip = (tabstrip, data, messageDispatch, identity) => {
    let passValidation = true;
    //for (const ti in tabstrip) {
        const t = tabstrip[0];
        if (t && t.Form && t.Form.SectionGroups) {
            if (!ValidateSectionGroups(t.Form.SectionGroups, data, messageDispatch, identity)) {
                passValidation = false;
            }
        }
    //}
    return passValidation;
};

export const ValidateScreen = (screen, data, messageDispatch, identity) => {
    let passValidation = true;
    if (screen.ScreenToolbar && !ValidateScreenToolbar(screen.ScreenToolbar, data, messageDispatch, identity)) {
        passValidation = false;
    }
    if (screen.Wrap && !ValidateWrap(screen.Wrap, data, messageDispatch, identity)) {
        passValidation = false;
    }
    if (screen.SectionGroups && !ValidateSectionGroups(screen.SectionGroups, data, messageDispatch, identity)) {
        passValidation = false;
    }
    if (screen.Tabstrip && !ValidateTabstrip(screen.Tabstrip, data, messageDispatch, identity)) {
        passValidation = false;
    }
    return passValidation;
};

export const ColumnMonthExpiration = (text, month, type, warningClass, errorClass) => {
    if (new Date(text) < new Date()) {
        return [type, [errorClass]];
    }
    if (new Date(text) < AddMonths(new Date(), month)) {
        return [type, [warningClass]];
    }
    return [type, null];
};

export const ColumnValues = (type, message, data, condition, dependency, object, fieldType) => {
    // can use 'expr-eval' package for string condition evaluation
    
    let val1 = data,
        val2 = typeof(dependency) === 'string' ? object[dependency] : dependency;

    if(dependency === 'palletCount') {
        if(type === 'weight') {
            val2 *= 2600;
        }

        // if(type === 'space') {
        //     if(+val1 === 0) {
        //         if(val2 === 1) {
        //             return;
        //         } else {
        //             return 'Spaces can\'t be 0 if pallets greater then 1';
        //         }
        //     }
        // }
    }

    switch(fieldType) {
        case ColumnType.Date:
            if(moment(val2, [Defines.Format.PrintDate, Defines.Format.PrintDateTime, 'YYYY-MM-DDTHH:mm:ss']).isAfter(moment(val1, [Defines.Format.PrintDate, Defines.Format.PrintDateTime, 'YYYY-MM-DDTHH:mm:ss']))) {
                return message;
            }
            
            return;
        default:
            break;
    }

    if(Compare(val1, val2, condition)) {
        return message;
    }
};